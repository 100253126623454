import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { colors } from '../styles/variables'

export const ImplementationHeader = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1px;
  padding-top: 32px;
  text-transform: uppercase;
`

export const MDWrapper = styled.div`
  display: flex;
  flex-direction: column;
  img {
    border-radius: 16px;
  }
  .gatsby-resp-image-wrapper {
    margin: 32px 0;
  }
  /* align-items: center; */
  .inco-recommendation-tip {
    border-radius: 32px;
    background: ${colors.plain.nuances3};
    padding: 48px;
    margin-top: 48px;
    h2 {
      margin-top: 0;
      margin-bottom: 24px;
      color: ${colors.plain.nuances9};
      &:not(:first-of-type) {
        margin-top: 24px;
      }
    }
    p {
      margin-bottom: 0;
      color: ${colors.plain.nuances8};
    }
    a {
      cursor: pointer;
      box-shadow: inset 0 -1px ${colors.plain.redLight};
    }
  }

  .inco-implementation-tip {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    padding-top: 32px;
    text-transform: uppercase;
  }

  .inco-recommendation-description {
    font-size: 18px;
    color: ${colors.plain.nuances8};
    font-family: Source Sans Pro;
    margin-top: 16px;
  }

  .inco-content-custom-insert {
    border-radius: 32px;
    background: ${colors.gradient.nuances2};
    padding: 48px;
    @media (max-width: 650px) {
      padding: 32px;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 24px;
      color: ${colors.plain.nuances10};
    }
    p {
      margin-bottom: 0;
      color: ${colors.plain.nuances8};
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a {
      cursor: pointer;
      box-shadow: inset 0 -1px ${colors.plain.redLight};
    }
  }
  .inco-content-custom-insert-separator {
    margin-top: 16px;
  }
  blockquote,
  blockquote > p {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    color: ${colors.plain.nuances8};
  }

  blockquote {
    border-left: none;
    padding: 48px;
  }

  img {
    margin: 30px 0;
  }

  ul {
    margin-top: 30px;
    list-style: none;
    color: ${colors.plain.nuances8};
    font-family: Source Sans Pro;
    line-height: 25px;
    font-size: 18px;
    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      &:before {
        padding-right: 15px;
        content: '•';
      }
    }
  }

  p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 31px;
    color: ${colors.plain.nuances8};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  h1,
  h2,
  h3 {
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: Montserrat;
    color: ${colors.plain.nuances9};
  }

  h4,
  h5,
  h6 {
    margin-top: 16px;
    font-family: Source Sans Pro;
    color: ${colors.plain.nuances7};
  }

  h4 {
    margin-bottom: 32px;
  }

  a {
    box-shadow: inset 0 -1px ${colors.plain.redLight};
  }
  .gatsby-resp-image-link {
    box-shadow: none;
  }
`

export const RecoHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 48px;
  @media (max-width: 350px) {
    justify-content: flex-end;
    position: relative;
  }
`

export const ScalesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 600px) {
    transform: scale(0.7);
    transform-origin: right;
  }
`

export const Description = styled.p`
  font-size: 18px;
  color: ${colors.plain.nuances8};
  font-family: Source Sans Pro;
  margin-top: 16px;
`

export const Subtitle = styled.h4`
  margin-top: 16px;
  font-weight: 600;
`

export const MDRecoWrapper = styled(MDWrapper)`
  p {
    color: ${colors.plain.nuances8};
  }
  h4,
  h5,
  h6 {
    margin-top: 16px;
    font-family: Source Sans Pro;
    color: ${colors.plain.nuances8};
  }
`

export const RecoTemplateWrapper = styled.div`
  max-width: 900px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 42px;
  @media (max-width: 600px) {
    padding: 42px 16px;
  }
`

export const RecoTemplateInner = styled.div`
  border-radius: 64px;
  background: ${colors.gradient.nuances2};
  padding: 72px;
  @media (max-width: 600px) {
    padding: 32px;
  }
`

export const ContentWrapper = styled.div`
  max-width: 900px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 800px) {
    padding: 40px 32px;
  }
`

export const Title = styled.h1`
  align-self: flex-start;
  margin-top: 54px;
`

export const Banner = styled(Img)`
  width: 100%;
  height: 450px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
`

export const LinkWrapper = styled.div`
  padding-bottom: 40px;
`

export const RecoSection = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 840px;
  width: 100%;
  margin: 70px 32px;
  border-radius: 32px;
  background: linear-gradient(180deg, #312a34 0%, #1b171d 100%);
  @media (max-width: 860px) {
    width: 80%;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    width: unset;
  }
`

export const ColumnLeft = styled.div`
  margin: 58px 64px;
  max-width: 408px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 800px) {
    order: 2;
    margin-top: 32px;
  }
  @media (max-width: 420px) {
    margin: 32px;
  }
`

export const RecoTitle = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  margin: 0;
  padding-bottom: 16px;
`

export const RecoText = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: ${colors.plain.nuances7};
`

export const ColumnRight = styled.div`
  align-items: center;
  width: 100%;
`

export const RecoIllu = styled(Img)`
  max-height: 370px;
  @media (max-width: 800px) {
    display: none;
  }
`

export const MobileRecoIllu = styled(Img)`
  margin: 32px 32px 0 32px;
  @media (min-width: 800px) {
    display: none;
  }
  @media (max-width: 420px) {
    /* display: none; */
  }
`

export const ColumnOverlay = styled.div`
  position: absolute;
  background: linear-gradient(180deg, #3b343e 0%, rgba(39, 32, 42, 0) 28.13%, rgba(39, 32, 42, 0) 71.88%, #27202a 100%);
  top: 0;
  bottom: 0;
  z-index: 2;
  left: 0;
  right: 0;
  border-radius: 32px;
`

export const ButtonWrapper = styled.div`
  padding-top: 32px;
`

export const ContentSuggestions = styled.div`
  background: ${colors.plain.nuances1Bis};
  padding: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 650px) {
    padding: 100px 32px;
  }
`

export const SuggestionTitle = styled.h2`
  font-family: Montserrat;
  text-align: center;
`

export const SuggestionGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  padding: 60px 0;
  grid-template: 450px 450px / 1fr 1fr;
  @media (max-width: 800px) {
    grid-template: 450px / 1fr;
  }
`

export const SeeMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 72px;
  @media (max-width: 600px) {
    padding: 32px 16px;
  }
`
export const SeeMoreGrid = styled.div`
  padding-top: 43px;
  display: grid;
  grid-template: 250px 250px / 1fr 1fr;
  grid-gap: 32px;
  @media (max-width: 750px) {
    grid-template: 250px / 1fr;
  }
`
