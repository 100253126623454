import * as React from 'react'
import { graphql, Link } from 'gatsby'
import getAsset from '../services/getAsset'
import IconLink from '../components/Common/IconLink'
import {
  ContentWrapper,
  Title,
  Banner,
  LinkWrapper,
  MDWrapper,
  RecoSection,
  ColumnLeft,
  ColumnRight,
  RecoText,
  RecoTitle,
  ButtonWrapper,
  ContentSuggestions,
  SuggestionTitle,
  SuggestionGrid,
  RecoIllu,
  MobileRecoIllu
} from './style'
import { Card, CardContent, CardType, Cover, CardTitle, CardSubtitle } from '../pages/content'
import { ColorLink } from '../components/Common/Buttons'

import IndexLayout from '../layouts'

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        cover: {
          publicURL: string
        }
      }
      fields: {
        slug: string
      }
    }
    allMarkdownRemark: {
      edges: Array<{
        node: {
          frontmatter: {
            title: string
            type: string
            subtitle: string
            cover: {
              publicURL: string
            }
          }
          fields: {
            slug: string
          }
        }
      }>
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const { title, cover } = data.markdownRemark.frontmatter
  const { edges } = data.allMarkdownRemark
  // Faire random à partir de startindex puis aller au début
  const contentSuggestion = edges.slice(0, 4)
  const singleRecoCardsAsset = getAsset('reco-illu-single-col')
  const mobileRecoCardsAsset = getAsset('reco-mobile')

  return (
    <IndexLayout>
      <ContentWrapper>
        <LinkWrapper>
          <IconLink to="/content" label="Back" />
        </LinkWrapper>
        {cover && <Banner fluid={cover?.childImageSharp.fluid} />}
        <Title>{title}</Title>
        <MDWrapper>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </MDWrapper>
      </ContentWrapper>
      <RecoSection>
        <ColumnLeft>
          <RecoTitle>Get custom recommendations to Pow’Her Tech</RecoTitle>
          <RecoText style={{ marginBottom: 16 }}>
            Accelerate efforts to empower women to join and thrive in the tech sector locally and internationally. Be challenged to create
            more inclusive culture, intentionally. Start here!
          </RecoText>
          <ButtonWrapper>
            <ColorLink to="/survey">Get recommendations</ColorLink>
          </ButtonWrapper>
        </ColumnLeft>
        <ColumnRight>
          <RecoIllu
            fluid={singleRecoCardsAsset?.childImageSharp?.fluid}
            imgStyle={{
              objectFit: 'contain'
            }}
          />
          <MobileRecoIllu fluid={mobileRecoCardsAsset?.childImageSharp?.fluid} />
        </ColumnRight>
      </RecoSection>

      <ContentSuggestions>
        <SuggestionTitle>Read more content</SuggestionTitle>
        <SuggestionGrid>
          {contentSuggestion.map(({ node }) => {
            const { title: cardTitle, subtitle, cover: cardCover, type } = node.frontmatter
            const { fields } = node
            return (
              <Link key={`content_${cardTitle}`} to={`/content${fields.slug}`}>
                <Card isCoverLeft={false}>
                  {cover && <Cover fluid={cardCover?.childImageSharp?.fluid} isCoverLeft={false} />}
                  <CardContent isCoverLeft={false}>
                    <CardType type={type}>{type}</CardType>
                    <CardTitle>{cardTitle}</CardTitle>
                    <CardSubtitle>{subtitle}</CardSubtitle>
                  </CardContent>
                </Card>
              </Link>
            )
          })}
        </SuggestionGrid>
      </ContentSuggestions>
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        cover {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
    allMarkdownRemark(filter: { fields: { type: { eq: "content" }, slug: { ne: $slug } } }) {
      edges {
        node {
          frontmatter {
            title
            type
            subtitle
            cover {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
